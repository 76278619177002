import { useState } from 'react';

import { useRouter } from 'next/router';

import { NarrationsTypeToggle } from '@components/NarrationsTypeToggle';
import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import HadithRulingsResultItem from '@components/results/items/HadithRulingsResultItem';
import { getBookAndIdText } from '@components/results/items/helpers';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { rulingsIndex } from '@config/reactive-search';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';

import HadithRulingsFilters from './HadithRulingsFilters';
import { HadithRulingsDataFields } from 'constants/datafields';
import { HadithRulingsViewFilters } from 'constants/filters';
import {
    BookReference,
    HadithNumbers,
    NarrationsType,
    NextState,
    RulerRoadsRulings,
    SearchPageHadith,
} from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    data: z.array(RulerRoadsRulings),
    loading: z.boolean(),
});

type BookReferenceWithSorting = BookReference & {
    ruler_dod: number;
};

interface HadithRulingsViewProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
    narrationsType: NarrationsType;
    setNarrationsType: (x: NarrationsType) => void;
}

const RulingsReferences = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: z
                    .object({
                        ruler: z.string(),
                        ruler_dod: z.number(),
                        rulings: z
                            .object({
                                book_name: z.string().nullable(),
                                page: z.number().nullable(),
                                volume: z.number().nullable(),
                                hadith_id: z.string().nullable(),
                                esIndex: z.number().optional(),
                            })
                            .array(),
                    })
                    .transform((obj) => {
                        obj.rulings.forEach((ruling, index) => {
                            ruling.esIndex = index;
                        });

                        return obj;
                    }),
            })
            .array(),
    }),
});

const size = 50;

function getRulingsReferences(
    fullSearchState: NextState,
    rulingsCount: number,
): Promise<BookReference[]> {
    const jsonBody = JSON.stringify({
        query: fullSearchState['rul_result'].query,
        aggs: {},
        size: rulingsCount,
        from: 0,
        _source: {
            includes: [
                HadithRulingsDataFields.RULER,
                HadithRulingsDataFields.RULER_DOD,
                HadithRulingsDataFields.RULINGS,
            ],
            excludes: [],
        },
    });

    return fetch(`/api/reactivesearchproxy/${rulingsIndex}/_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    })
        .then((res) => res.json())
        .then((json) => {
            const {
                hits: { hits },
            } = RulingsReferences.parse(json);

            const rulingsReferences: BookReferenceWithSorting[] = hits
                .map((hit) => {
                    // only defined if 'type' is 'embedded' not 'external'
                    return hit._source.rulings.map((ruling) => {
                        const hadithNumber = ruling.hadith_id?.split('-')?.[1];
                        const bookNumberText = ruling.book_name
                            ? getBookAndIdText(ruling.book_name, hadithNumber)
                            : '';
                        return {
                            page: ruling.page ?? 0,
                            volume: ruling.volume ?? 0,
                            book_name: ruling.book_name ?? '',
                            id: `${hit._source.ruler}-${ruling.esIndex}`,
                            label: `${bookNumberText} (${ruling.page ?? 0}/${
                                ruling.volume ?? 0
                            })`,
                            group: hit._source.ruler,
                            ruler_dod: hit._source.ruler_dod,
                            editionsRefs: [],
                        };
                    });
                })
                .flat(1)
                .filter(
                    (reference) =>
                        reference.book_name &&
                        reference.page &&
                        reference.volume,
                );

            return rulingsReferences.sort((a, b) => {
                if (a.group !== b.group) {
                    // sort on group! YOU ALWAYS HAVE TO DO THIS! so grouped entries
                    // actually end up contiguous / next to each other
                    return a.ruler_dod - b.ruler_dod;
                }

                // within the same group (ruler) sort the rulings on the hadith
                // roads by page and volume of ruling

                if (a.volume !== b.volume) {
                    return (a.volume ?? 0) > (b.volume ?? 0) ? 1 : -1;
                }

                return (a.page ?? 0) > (b.page ?? 0) ? 1 : -1;
            });
        });
}

const HadithRulingsView = ({
    hadith,
    narrationsType,
    setNarrationsType,
}: HadithRulingsViewProps) => {
    const { t } = useTranslation('library');
    const router = useRouter();
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);

    const [rulingsReferences, setRulingsReferences] = useState<BookReference[]>(
        [],
    );

    const [totalResults, setTotalResults] = useState(0);

    const resultStatsLabel = hasSelectedFilters
        ? t('rulings_count_with_filters')
        : t('rulings_count');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('rulings_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );
    const [collapsed, setCollapsed] = useState(true);

    const narrationsTypeQuery =
        narrationsType === 'all'
            ? {
                  terms: {
                      [HadithRulingsDataFields.NARRATIONS_TYPE]: [
                          'raw',
                          'extended',
                      ],
                  },
              }
            : {
                  term: {
                      [HadithRulingsDataFields.NARRATIONS_TYPE]:
                          narrationsType === 'roads' ? 'raw' : 'extended',
                  },
              };

    return (
        <SearchBase
            app={rulingsIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            <ReactiveComponent
                componentId={HadithRulingsViewFilters.HADITH_ID}
                customQuery={() => ({
                    query: {
                        bool: {
                            filter: [
                                {
                                    term: {
                                        [HadithRulingsDataFields.HADITH_ID]:
                                            hadith.hadith_id,
                                    },
                                },
                                narrationsTypeQuery,
                            ],
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <HadithRulingsFilters
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        <Box
                            sx={
                                isMobile
                                    ? {
                                          display: 'flex',
                                          justifyContent: 'center',
                                          mt: 1,
                                      }
                                    : {
                                          mt: 4,
                                          display: 'flex',
                                          justifyContent: 'start',
                                      }
                            }
                        >
                            <NarrationsTypeToggle
                                narrationsType={narrationsType}
                                setNarrationsType={setNarrationsType}
                                disabledShawahed={!hadith.hasExtendedRuling}
                                disabledShawahedMessage={t(
                                    'extended_rulings_disabled',
                                )}
                            />
                        </Box>
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            resultStatsMessage={resultStatsMessage}
                            componentId={HadithRulingsViewFilters.RESULT}
                            dataField={HadithRulingsDataFields.HADITH_ID}
                            onData={(data) => {
                                const hits = data.data as RulerRoadsRulings[];
                                const totalResults = hits
                                    .map((ruler) => ruler.rulings.length)
                                    .reduce(
                                        (acc, rulCount) => acc + rulCount,
                                        0,
                                    );
                                setTotalResults(totalResults);
                            }}
                            dependencies={{
                                and: [
                                    HadithRulingsViewFilters.HADITH_ID,
                                    HadithRulingsViewFilters.RULERS_FILTERS,
                                ],
                            }}
                            defaultQuery={() => ({
                                sort: [
                                    {
                                        [HadithRulingsDataFields.RULER_DOD]: {
                                            order: 'asc',
                                        },
                                    },
                                ],
                            })}
                            includeFields={[
                                HadithRulingsDataFields.HADITH_ID,
                                HadithRulingsDataFields.NARRATIONS_TYPE,
                                HadithRulingsDataFields.RULER,
                                HadithRulingsDataFields.RULER_DOD,
                                HadithRulingsDataFields.RULINGS,
                            ]}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);

                                const hadithRulings = data.map((item) => (
                                    <HadithRulingsResultItem
                                        key={uuidv4()}
                                        ruler={item.ruler}
                                        rulerDod={item.ruler_dod}
                                        rulings={item.rulings}
                                        quickNavPoints={rulingsReferences}
                                    />
                                ));

                                if (loading && data.length > size) {
                                    return hadithRulings.concat(
                                        Array(size)
                                            .fill(0)
                                            .map(() => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions={false}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map(() => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions={false}
                                            />
                                        ));
                                }

                                return hadithRulings.concat(
                                    <MoreResultsNotice
                                        key="more-results-notice"
                                        // we always fetch all of them here
                                        moreResults={false}
                                    />,
                                );
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map(() => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions={false}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            onFilterClick={handleFilter}
                            scrollTarget={'main-modal'}
                        />

                        <StateProvider
                            //Counts coming from aggregations query
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            onChange={(state, nextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    'rul_',
                                );

                                if (nextState?.rul_result?.hits?.total) {
                                    getRulingsReferences(
                                        nextState,
                                        nextState['rul_result'].hits.total,
                                    ).then((references) => {
                                        setRulingsReferences(references);
                                    });
                                }
                            }}
                        />
                    </>
                )}
            />
            {/* TODO[chammaaomar]: Get this to work for scrollable container */}
            {/* <ScrollToTop smooth top={scrollToTopMin} containerId="main-modal" /> */}
        </SearchBase>
    );
};

export default HadithRulingsView;
