import { useRouter } from 'next/router';

import { SelectedFilters } from '@appbaseio/reactivesearch';
import { DEFAULT_SEARCH_TYPE, SearchFilters } from '@constants/filters';
import { SORT_OPTIONS } from '@constants/sorts';
import useTranslation from '@hooks/useTranslation';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Box,
    ButtonBase,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import { SearchType } from 'shared/interfaces/hadith';

const FiltersSelected = ({
    addShadow = false,
    setLoadingCounts,
    setSearchType,
    setSortBy,
    setTextSearch,
}: {
    addShadow?: boolean;
    setLoadingCounts?: (loading: boolean) => void;
    // the following only used in main search
    setSearchType?: (x: SearchType) => void;
    setSortBy?: (x: SORT_OPTIONS) => void;
    setTextSearch?: (x: string) => void;
}) => {
    const { t } = useTranslation('library');
    const router = useRouter();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const maxLength = isMobile ? 24 : 48;

    return (
        <SelectedFilters
            render={(props) => {
                const { selectedValues } = props;
                let hasValues = false;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: { xs: 'initial', md: 'wrap' },
                            overflowX: { xs: 'scroll', md: 'unset' },
                            gap: 1,
                            px: { xs: 1, sm: 0 },
                            py: { xs: 1, sm: 0 },
                            mt: { xs: -0.04, sm: 0 },
                            backgroundColor: '#fff',
                            width: '100%',
                            zIndex: { xs: 2, sm: 'initial' },
                            boxShadow: {
                                xs: addShadow
                                    ? '0 5px 10px -2px rgba(0, 0, 0, 0.19)'
                                    : 'unset',
                                sm: 'unset',
                            },
                        }}
                        className={'selectedFiltersMainPage'}
                    >
                        {Object.keys(selectedValues)
                            .filter(
                                (id) =>
                                    props.components.includes(id) &&
                                    selectedValues[id].showFilter,
                            )
                            .map((component, index) => {
                                const { label, value } =
                                    selectedValues[component];
                                const isArray = Array.isArray(value);
                                const remove = (component: string) => {
                                    props.setValue(component, '');
                                    if (setLoadingCounts) {
                                        setLoadingCounts(true);
                                    }
                                };

                                // truncate the value if it's a string or a string array
                                const truncatedValue = Array.isArray(value)
                                    ? value
                                          .join(' - ')
                                          .substring(0, maxLength) + '...'
                                    : typeof value === 'string'
                                    ? value.substring(0, maxLength) + '...'
                                    : value;

                                if (
                                    label &&
                                    ((isArray && value.length) ||
                                        (!isArray && value))
                                ) {
                                    hasValues = true;
                                    return (
                                        <ButtonBase
                                            component="div"
                                            key={`${component}-${index}`}
                                            onClick={() => {
                                                remove(component);
                                                if (
                                                    component ===
                                                    SearchFilters.SEARCH
                                                ) {
                                                    if (setSearchType) {
                                                        setSearchType(
                                                            DEFAULT_SEARCH_TYPE,
                                                        );
                                                    }
                                                    if (setSortBy) {
                                                        setSortBy(
                                                            SORT_OPTIONS.AUTHENTICITY,
                                                        );
                                                    }
                                                    if (setTextSearch) {
                                                        setTextSearch('');
                                                    }

                                                    delete router.query[
                                                        'search_type'
                                                    ];
                                                    delete router.query['sort'];
                                                    delete router.query[
                                                        'search'
                                                    ];

                                                    router.push(router);
                                                }
                                            }}
                                            sx={{
                                                backgroundColor:
                                                    'rgba(199, 149, 109, 0.2)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                py: 0.7,
                                                mb: 1,
                                                px: 1,
                                                color: '#121212',
                                                borderRadius: '20px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    textDecoration: {
                                                        xs: 'none',
                                                        md: 'line-through',
                                                    },
                                                    backgroundColor:
                                                        'rgba(199, 149, 109, 0.2)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    paddingLeft: 8,
                                                    paddingRight: 4,
                                                    fontSize: 12,
                                                    fontWeight: 300,
                                                    whiteSpace: 'nowrap',
                                                    wordBreak: 'keep-all',
                                                }}
                                            >
                                                {label}: {truncatedValue}
                                            </Typography>
                                            <CancelIcon
                                                color="secondary"
                                                fontSize="small"
                                            />
                                        </ButtonBase>
                                    );
                                }
                                return null;
                            })}
                        {props.showClearAll && hasValues ? (
                            <ButtonBase
                                component="div"
                                id="clear-all-filters"
                                onClick={() => {
                                    if (setLoadingCounts) {
                                        setLoadingCounts(true);
                                    }
                                    const mainSearchApplied = Object.keys(
                                        selectedValues,
                                    ).some(
                                        (id) =>
                                            id === SearchFilters.SEARCH &&
                                            selectedValues[id].showFilter,
                                    );

                                    Object.keys(selectedValues)
                                        .filter(
                                            (id) =>
                                                props.components.includes(id) &&
                                                selectedValues[id].showFilter,
                                        )
                                        .map((component, index) => {
                                            props.setValue(component, '');
                                            // when clearing the search we also need to return the default settings:
                                            // - search type: token
                                            // - default sort: authenticity
                                            // we also need to take care to _manually_ sync the other filters
                                            // usually we wouldn't have to, but if we just do router.push(router)
                                            // without manually syncing the other filters, they get overwritten
                                            // and thus persist instead of getting cleared
                                            // we ONLY do this in case we're clearing filters with main search present
                                            if (
                                                component ===
                                                SearchFilters.SEARCH
                                            ) {
                                                if (setSearchType) {
                                                    setSearchType(
                                                        DEFAULT_SEARCH_TYPE,
                                                    );
                                                }
                                                if (setSortBy) {
                                                    setSortBy(
                                                        SORT_OPTIONS.AUTHENTICITY,
                                                    );
                                                }
                                                if (setTextSearch) {
                                                    setTextSearch('');
                                                }

                                                delete router.query[
                                                    'search_type'
                                                ];
                                                delete router.query['sort'];
                                                delete router.query['search'];
                                            } else {
                                                delete router.query[component];
                                            }
                                        });

                                    if (mainSearchApplied) {
                                        router.push(router);
                                    }
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(130, 0, 0, 0.1)',
                                    color: '#121212',
                                    mb: 1,
                                    py: 0.7,
                                    px: 1,
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(130, 0, 0, 0.2)',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <Typography
                                    style={{
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        fontSize: 12,
                                        fontWeight: 300,
                                        color: '#121212',
                                        whiteSpace: 'nowrap',
                                        wordBreak: 'keep-all',
                                    }}
                                >
                                    {t('clear_all')}
                                </Typography>
                                <DeleteForeverIcon
                                    fontSize="small"
                                    sx={{ color: '#121212' }}
                                />
                            </ButtonBase>
                        ) : null}
                    </Box>
                );
            }}
        />
    );
};

export default FiltersSelected;
