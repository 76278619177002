import { useState } from 'react';

import { useRouter } from 'next/router';
import ScrollToTop from 'react-scroll-to-top';

import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import NarratorsCommentaryResultItem from '@components/results/items/NarratorsCommentaryResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { commentaryIndex } from '@config/reactive-search';
import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';
import { narr_comm_auth_order } from '@utils/sortBooks';

import NarratorCommentaryFilters from './NarratorCommentaryFilters';
import { NarratorsCommentaryDataFields } from 'constants/datafields';
import {
    HadithNarratorCommentaryViewFilters,
    NarratorCommentaryViewFilters,
} from 'constants/filters';
import {
    BookReference,
    NextState,
    StatePrefix,
} from 'shared/interfaces/hadith';
import { NarratorCommentary } from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    data: NarratorCommentary.array(),
    loading: z.boolean(),
});

const NarratorsCommentaryReferences = z.object({
    hits: z.object({
        hits: z
            .object({
                _id: z.string(),
                _source: z.object({
                    page: z.number().nullable(),
                    volume: z.number().nullable(),
                    book: z.string().nullable(),
                    commenter: z.string(),
                }),
            })
            .array(),
    }),
});

const size = 20;

function getNarratorsCommentaryReferences(
    fullSearchState: NextState,
    narratorsCommentaryCount: number,
    // since the narrator commentary may be in the narrator view or
    // the hadith view (by clicking on the narrators tab in the hadithresultitem)
    resultComponentId:
        | typeof NarratorCommentaryViewFilters.RESULT
        | typeof HadithNarratorCommentaryViewFilters.RESULT,
): Promise<BookReference[]> {
    const jsonBody = JSON.stringify({
        query: fullSearchState[resultComponentId].query,
        aggs: {},
        size: narratorsCommentaryCount,
        from: 0,
        _source: {
            includes: [
                NarratorsCommentaryDataFields.PAGE,
                NarratorsCommentaryDataFields.VOLUME,
                NarratorsCommentaryDataFields.BOOK,
                NarratorsCommentaryDataFields.COMMENTER,
            ],
            excludes: [],
        },
    });

    return fetch(`/api/reactivesearchproxy/${commentaryIndex}/_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    })
        .then((res) => res.json())
        .then((json) => {
            const {
                hits: { hits },
            } = NarratorsCommentaryReferences.parse(json);

            const narratorsCommentaryReferences: BookReference[] = hits
                .map((hit) => ({
                    page: hit._source.page ?? 0,
                    volume: hit._source.volume ?? 0,
                    book_name: hit._source.book ?? '',
                    id: hit._id,
                    group: hit._source.book ?? '',
                    label: hit._source.commenter ?? '',
                    editionsRefs: [],
                }))
                .sort((a, b) => {
                    if (a.group !== b.group) {
                        // sort on group! YOU ALWAYS HAVE TO DO THIS! so grouped entries
                        // actually end up contiguous / next to each other
                        const narrCommBookA =
                            a.group as keyof typeof narr_comm_auth_order;
                        const narrCommBookB =
                            b.group as keyof typeof narr_comm_auth_order;
                        if (
                            narr_comm_auth_order[narrCommBookA] >
                            narr_comm_auth_order[narrCommBookB]
                        ) {
                            return 1;
                        } else if (
                            narr_comm_auth_order[narrCommBookB] >
                            narr_comm_auth_order[narrCommBookA]
                        ) {
                            return -1;
                        }
                    }

                    // sort alphabetically on commenter name
                    return a.label > b.label ? 1 : -1;
                });
            return narratorsCommentaryReferences;
        });
}

const NarratorCommentaryView = ({
    narratorId,
    containerId,
    routing = ROUTING.HADITH_PAGE_NARRATOR,
}: {
    narratorId?: string;
    containerId?: string;
    routing?: ROUTING;
}) => {
    const router = useRouter();
    const { t } = useTranslation('library');
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);

    const scrollToTopMin =
        typeof window !== 'undefined' ? window.innerHeight * 3 : undefined;

    const [totalResults, setTotalResults] = useState(0);

    const resultStatsLabel = hasSelectedFilters
        ? t('narrators_commentary_count_with_filters')
        : t('narrators_commentary_count');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('narrators_commentary_count')}: ${numberWithCommas(
                totalResults,
            )}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );

    const [collapsed, setCollapsed] = useState(true);
    const [narratorsCommentaryReferences, setNarratorsCommentaryReferences] =
        useState<BookReference[]>([]);

    if (!narratorId) {
        return null;
    }

    const CommentaryViewFilters:
        | typeof NarratorCommentaryViewFilters
        | typeof HadithNarratorCommentaryViewFilters =
        routing === ROUTING.NARRATOR_PAGE
            ? NarratorCommentaryViewFilters
            : HadithNarratorCommentaryViewFilters;

    const statePrefix: StatePrefix =
        routing === ROUTING.NARRATOR_PAGE ? 'narr_comm_' : 'hadith_narr_comm_';

    return (
        <SearchBase
            app={commentaryIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            <ReactiveComponent
                componentId={CommentaryViewFilters.NARRATOR_ID}
                customQuery={() => ({
                    query: {
                        match: {
                            [NarratorsCommentaryDataFields.ID]: narratorId,
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <NarratorCommentaryFilters
                        routing={routing}
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            resultStatsMessage={resultStatsMessage}
                            componentId={CommentaryViewFilters.RESULT}
                            dataField={NarratorsCommentaryDataFields.ID}
                            onData={(data) =>
                                setTotalResults(
                                    data.resultStats.numberOfResults,
                                )
                            }
                            dependencies={{
                                and: [
                                    CommentaryViewFilters.NARRATOR_ID,
                                    CommentaryViewFilters.BOOKS_FILTERS,
                                    CommentaryViewFilters.COMMENTATOR_FILTERS,
                                ],
                            }}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);

                                const narratorCommentaries = data.map(
                                    (item) => (
                                        <NarratorsCommentaryResultItem
                                            author={item.author}
                                            narratorId={narratorId}
                                            key={uuidv4()}
                                            commId={item._id}
                                            comments={item.comments}
                                            commentator={item.commenter}
                                            book={item.book}
                                            page={item.page}
                                            volume={item.volume}
                                            quickNavPoints={
                                                narratorsCommentaryReferences
                                            }
                                            reportErrorId={JSON.stringify(
                                                {
                                                    narrator_id: narratorId,
                                                    author: item.commenter,
                                                    book: item.book,
                                                    volume: item.volume,
                                                    page: item.page,
                                                },
                                                null,
                                                2,
                                            )}
                                        />
                                    ),
                                );

                                if (loading && data.length > size) {
                                    return narratorCommentaries.concat(
                                        Array(size)
                                            .fill(0)
                                            .map(() => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions={false}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map(() => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions={false}
                                            />
                                        ));
                                }

                                return narratorCommentaries.concat(
                                    <MoreResultsNotice
                                        key="more-results-notice"
                                        moreResults={
                                            narratorCommentaries.length <
                                            totalResults
                                        }
                                    />,
                                );
                            }}
                            includeFields={[
                                NarratorsCommentaryDataFields.AUTHOR,
                                NarratorsCommentaryDataFields.COMMENTER,
                                NarratorsCommentaryDataFields.COMMENTS,
                                NarratorsCommentaryDataFields.BOOK,
                                NarratorsCommentaryDataFields.NAME,
                                NarratorsCommentaryDataFields.PAGE,
                                NarratorsCommentaryDataFields.VOLUME,
                            ]}
                            defaultQuery={() => {
                                return {
                                    track_total_hits: true,
                                    aggs: {
                                        unique_commentators: {
                                            cardinality: {
                                                field: NarratorsCommentaryDataFields.COMMENTER,
                                            },
                                        },
                                    },
                                    sort: [
                                        {
                                            [NarratorsCommentaryDataFields.COMMENTER_DOD]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                        {
                                            [NarratorsCommentaryDataFields.COMMENTER]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                        {
                                            [NarratorsCommentaryDataFields.BOOK_ORDER]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                    ],
                                };
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map(() => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions={false}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            scrollTarget={containerId}
                            onFilterClick={handleFilter}
                        />

                        {/* since react-scroll-to-top only works with window, not scrollable containers */}
                        {/* {!containerId && (
                            <ScrollToTop
                                smooth
                                top={scrollToTopMin}
                                component={
                                    <KeyboardArrowUpIcon
                                        sx={{ color: 'white' }}
                                    />
                                }
                                style={{
                                    backgroundColor: colors.secondary,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bottom: '80px',
                                }}
                            />
                        )} */}

                        <StateProvider
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            onChange={(state, nextState: NextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    statePrefix,
                                );
                                if (
                                    nextState[CommentaryViewFilters.RESULT]
                                        ?.hits?.total
                                ) {
                                    getNarratorsCommentaryReferences(
                                        nextState,
                                        nextState[CommentaryViewFilters.RESULT]
                                            .hits.total,
                                        CommentaryViewFilters.RESULT,
                                    ).then((references) => {
                                        setNarratorsCommentaryReferences(
                                            references,
                                        );
                                    });
                                }
                            }}
                        />
                    </>
                )}
            />
        </SearchBase>
    );
};

export default NarratorCommentaryView;
