import FilterList from '@components/filters/FilterList';

import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import { Divider } from '@mui/material';

import { NarratorsCommentaryDataFields } from 'constants/datafields';
import {
    HadithNarratorCommentaryViewFilters,
    NarratorCommentaryViewFilters,
} from 'constants/filters';
import { StatePrefix } from 'shared/interfaces/hadith';

const NarratorCommentaryFilters = ({
    routing = ROUTING.HADITH_PAGE_NARRATOR,
    setDrawerFiltersOpen,
}: {
    routing?: ROUTING;
    setDrawerFiltersOpen: (open: boolean) => void;
}) => {
    const { t } = useTranslation('library');

    const CommentaryViewFilters:
        | typeof NarratorCommentaryViewFilters
        | typeof HadithNarratorCommentaryViewFilters =
        routing === ROUTING.NARRATOR_PAGE
            ? NarratorCommentaryViewFilters
            : HadithNarratorCommentaryViewFilters;

    const statePrefix: StatePrefix =
        routing === ROUTING.NARRATOR_PAGE ? 'narr_comm_' : 'hadith_narr_comm_';
    return (
        <>
            {/* Search By Books */}
            <FilterList
                label={t('books')}
                componentId={CommentaryViewFilters.BOOKS_FILTERS}
                dataField={NarratorsCommentaryDataFields.BOOK}
                dependencies={{
                    and: [
                        CommentaryViewFilters.NARRATOR_ID,
                        CommentaryViewFilters.COMMENTATOR_FILTERS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                statePrefix={statePrefix}
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Commentator */}
            <FilterList
                label={t('commentator')}
                countAggregateIdentifier={'unique_commentators'}
                componentId={CommentaryViewFilters.COMMENTATOR_FILTERS}
                dataField={NarratorsCommentaryDataFields.COMMENTER}
                searchType="server"
                searchComponentId={NarratorsCommentaryDataFields.COMMENTER}
                searchPlaceholder={t('search_for_a_commentator')}
                searchMoreMessage={t('commentary_author_search_more')}
                searchField={NarratorsCommentaryDataFields.COMMENTER_NORMALIZED}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        CommentaryViewFilters.NARRATOR_ID,
                        CommentaryViewFilters.BOOKS_FILTERS,
                    ],
                }}
                size={20}
                statePrefix={statePrefix}
            />
        </>
    );
};

export default NarratorCommentaryFilters;
