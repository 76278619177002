import FilterList from '@components/filters/FilterList';

import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import { Divider } from '@mui/material';
import {
    NarratorColor as StudentColor,
    handleNarratorColorChange as handleStudentColorChange,
} from '@utils/narratorColor';

import { NarrationsLinksDataFields } from 'constants/datafields';
import {
    HadithNarratorStudentsViewFilters,
    NarratorStudentsViewFilters,
} from 'constants/filters';
import { StatePrefix } from 'shared/interfaces/hadith';

interface INarratorScholarsFiltersProps {
    studentsColors: StudentColor[];
    setStudentsColors: (colors: StudentColor[]) => void;
    routing?: ROUTING;
    setDrawerFiltersOpen: (open: boolean) => void;
}

const NarratorStudentsFilters = ({
    studentsColors,
    setStudentsColors,
    routing = ROUTING.HADITH_PAGE_NARRATOR,
    setDrawerFiltersOpen,
}: INarratorScholarsFiltersProps) => {
    const { t } = useTranslation('library');

    const StudentsViewFilters:
        | typeof NarratorStudentsViewFilters
        | typeof HadithNarratorStudentsViewFilters =
        routing === ROUTING.NARRATOR_PAGE
            ? NarratorStudentsViewFilters
            : HadithNarratorStudentsViewFilters;

    const statePrefix: StatePrefix =
        routing === ROUTING.NARRATOR_PAGE ? 'narr_student_' : 'student_';

    return (
        <>
            {/* Search By Students */}
            <FilterList
                label={t('students_filter')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_students'}
                componentId={StudentsViewFilters.STUDENTS_FILTERS}
                dataField={NarrationsLinksDataFields.LINK_SRC_FULL_NAME}
                size={30}
                searchType="server"
                searchComponentId={StudentsViewFilters.STUDENTS_SEARCH}
                searchField={
                    NarrationsLinksDataFields.LINK_SRC_FULL_NAME_NORMALIZED
                }
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                searchPlaceholder={t('search_for_a_narrator')}
                searchMoreMessage={t('narrator_search_more')}
                dependencies={{
                    and: [
                        StudentsViewFilters.NARRATOR_ID,
                        StudentsViewFilters.BOOKS_FILTERS,
                        StudentsViewFilters.HADITH_VERB_FILTERS,
                        StudentsViewFilters.GRADES_FILTERS,
                        StudentsViewFilters.TYPES_FILTERS,
                    ],
                }}
                coloredItems={studentsColors}
                setColoredItems={(newList) =>
                    handleStudentColorChange(
                        newList,
                        studentsColors,
                        setStudentsColors,
                    )
                }
            />
            <Divider sx={{ my: '10px' }} />
            {/* Search By Books */}
            <FilterList
                label={t('books')}
                size={23}
                statePrefix={statePrefix}
                componentId={StudentsViewFilters.BOOKS_FILTERS}
                dataField={NarrationsLinksDataFields.HADITH_BOOK_NAME}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        StudentsViewFilters.HADITH_VERB_FILTERS,
                        StudentsViewFilters.GRADES_FILTERS,
                        StudentsViewFilters.NARRATOR_ID,
                        StudentsViewFilters.STUDENTS_FILTERS,
                        StudentsViewFilters.TYPES_FILTERS,
                    ],
                }}
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Narrator Grade */}
            <FilterList
                label={t('student_grade')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_grades'}
                dataField={NarrationsLinksDataFields.LINK_SRC_GRADE}
                componentId={StudentsViewFilters.GRADES_FILTERS}
                searchMoreMessage={t('student_grade_search_more')}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        StudentsViewFilters.NARRATOR_ID,
                        StudentsViewFilters.HADITH_VERB_FILTERS,
                        StudentsViewFilters.BOOKS_FILTERS,
                        StudentsViewFilters.STUDENTS_FILTERS,
                        StudentsViewFilters.TYPES_FILTERS,
                    ],
                }}
                searchType="server"
                searchComponentId={StudentsViewFilters.GRADES_SEARCH}
                searchField={
                    NarrationsLinksDataFields.LINK_SRC_GRADE_NORMALIZED
                }
                searchPlaceholder={t('search_for_a_grade')}
                size={20}
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Verb */}
            <FilterList
                label={t('hadith_delivering_verb')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_verbs'}
                dataField={NarrationsLinksDataFields.LINK_VERB}
                componentId={StudentsViewFilters.HADITH_VERB_FILTERS}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        StudentsViewFilters.GRADES_FILTERS,
                        StudentsViewFilters.BOOKS_FILTERS,
                        StudentsViewFilters.NARRATOR_ID,
                        StudentsViewFilters.STUDENTS_FILTERS,
                        StudentsViewFilters.TYPES_FILTERS,
                    ],
                }}
                searchType="server"
                searchComponentId={StudentsViewFilters.VERBS_SEARCH}
                searchField={NarrationsLinksDataFields.LINK_VERB_NORMALIZED}
                searchPlaceholder={t('search_for_a_verb')}
                size={20}
            />

            <Divider sx={{ my: '10px' }} />
            {/* Search By Type */}
            <FilterList
                label={t('hadith_type')}
                statePrefix={statePrefix}
                componentId={StudentsViewFilters.TYPES_FILTERS}
                dataField={NarrationsLinksDataFields.HADITH_TYPE}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        StudentsViewFilters.NARRATOR_ID,
                        StudentsViewFilters.HADITH_VERB_FILTERS,
                        StudentsViewFilters.GRADES_FILTERS,
                        StudentsViewFilters.STUDENTS_FILTERS,
                        StudentsViewFilters.BOOKS_FILTERS,
                    ],
                }}
            />
        </>
    );
};

export default NarratorStudentsFilters;
