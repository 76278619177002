import {
    type Dispatch,
    type SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { HadithController } from '@components';

import { ResultsStats } from '@components/ResultsStats';
import NumberSearch from '@components/filters/NumberSearch';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import { MainModal } from '@components/modal/Modal';
import CopyHadithModal from '@components/modal/Modal/CopyHadithModal';
import ResultItemList from '@components/results/elements/ResultItemList';
import HadithResultItem from '@components/results/items/HadithResultItem';
import { getBookAndIdText } from '@components/results/items/helpers';
import SearchBarAutosuggest from '@components/search/SearchBarAutosuggest';
import { getMainSearchDefaultQuery } from '@components/search/SearchBarAutosuggest/queryFunctions';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import MySnackbar, {
    type MySnackbarProps,
} from '@components/snackbar/Snackbar';

import { StateProvider } from '@appbaseio/reactivesearch';
import { env } from '@constants/env';
import { SORT_OPTIONS } from '@constants/sorts';
import { useContextualRouting, useParams } from '@hooks';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayoutSkeleton } from '@layout';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress, SxProps, type Theme } from '@mui/material';
import { Box, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { addAmbiguousSpan } from '@utils/addAmbiguousSpan';
import { type WordConfig } from '@utils/export';
import { NarratorColor } from '@utils/narratorColor';
import { sampleHadith } from '@utils/sampleHadith';

import { MainSearchFilters } from './MainSearchFilters';
import { HadithResultItemsProps, ResultItems } from './ResultItems';
import { SecondHeaderLevelDesktop } from './SecondHeaderLevelDesktop';
import { SecondLevelHeaderMobile } from './SecondLevelHeaderMobile';
import ShareActions from './ShareActions';
import SortComponent from './SortComponent';
import TokenPhraseTabs from './TokenPhraseTabs';
import { getIncludeFields } from './function';
import { NarrationsDataFields } from 'constants/datafields';
import { DEFAULT_SEARCH_TYPE, SearchFilters } from 'constants/filters';
import {
    type NextState,
    SearchPageHadith,
    type SearchType,
} from 'shared/interfaces/hadith';
import { anyMainSearchFilterApplied } from 'shared/methods';
import { colors } from 'theme';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

// lazy imports

const DynamicSearchLayout = dynamic(
    () => import('@layout/ReactiveSearchLayout/ReactiveSearchLayout'),
    {
        loading: () => <ReactiveSearchLayoutSkeleton />,
        ssr: false,
    },
);

const ScrollToTop = dynamic(() => import('react-scroll-to-top'), {
    ssr: false,
});

const MatchAll = z
    .object({
        match_all: z.object({}),
    })
    .or(
        z.object({
            bool: z.object({
                must: z.tuple([
                    z.object({
                        bool: z.object({
                            must: z.tuple([
                                z.object({
                                    match_all: z.object({}),
                                }),
                            ]),
                        }),
                    }),
                ]),
            }),
        }),
    );

interface Props {
    collapsed: boolean;
    showFiltersMobileMainSearch: boolean;
    openMobileSearchModalFromHeader: boolean;
    setOpenMobileSearchModalFromHeader: Dispatch<SetStateAction<boolean>>;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
    setShowFiltersMobileMainSearch: Dispatch<SetStateAction<boolean>>;
    setSelectedFiltersPoint: Dispatch<SetStateAction<boolean>>;
    textSearch: string;
    setTextSearch: (x: string) => void;
}

const MainSearch = ({
    collapsed,
    setCollapsed,
    showFiltersMobileMainSearch,
    setShowFiltersMobileMainSearch,
    openMobileSearchModalFromHeader,
    setOpenMobileSearchModalFromHeader,
    setSelectedFiltersPoint,
    textSearch,
    setTextSearch,
}: Props) => {
    const firstRender = useRef(true);
    let countsTimeoutId = useRef<NodeJS.Timeout>();
    const scrollToTopMin =
        typeof window !== 'undefined' ? window.innerHeight * 3 : undefined;
    const { t } = useTranslation('library');
    const router = useRouter();
    const searchTypeFromUrl = (router.query['search_type'] ??
        DEFAULT_SEARCH_TYPE) as SearchType;
    const [searchType, setSearchType] = useState<SearchType>(searchTypeFromUrl);

    const initialHadithNumberSearch =
        router.query[SearchFilters.HADITH_NUMBER_SEARCH] ?? '0';

    const { returnHref } = useContextualRouting();

    const sortOptions =
        textSearch && searchType === 'token'
            ? [SORT_OPTIONS.SIMILARITY]
            : [
                  SORT_OPTIONS.AUTHENTICITY,
                  SORT_OPTIONS.OLDEST_TO_NEWEST,
                  SORT_OPTIONS.NEWEST_TO_OLDEST,
              ];

    const sortByFromUrl = (router.query['sort'] ??
        sortOptions[0]) as SORT_OPTIONS;
    const [sortBy, setSortBy] = useState(sortByFromUrl);

    useEffect(() => {
        const sortByFromUrl = (router.query['sort'] ??
            sortOptions[0]) as SORT_OPTIONS;
        if (sortByFromUrl !== sortBy) {
            setSortBy(sortByFromUrl);
        }

        const searchTypeFromUrl = (router.query['search_type'] ??
            DEFAULT_SEARCH_TYPE) as SearchType;

        if (searchTypeFromUrl !== searchType) {
            setSearchType(searchTypeFromUrl);
        }
    }, [router]);

    const [results, setResults] = useState<SearchPageHadith[] | null>(null);
    const [hadith, setHadith] = useState<SearchPageHadith>();
    // this also considers search as a selected filter
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);
    // this one only considers filters selected from the side menu, not search
    const [anyFilterApplied, setAnyFilterApplied] = useState(false);

    const [loadingCounts, setLoadingCounts] = useState(true);

    const [totalResults, setTotalResults] = useState(0);
    const [totalMatns, setTotalMatns] = useState(0);

    const isPhraseMatch = searchType === 'phrase';

    const isTokenMatch = searchType === 'token';

    const size = 10;

    const exportDisabledTooMany = totalResults > 200;

    const exportDisabledZero = totalResults === 0;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const resultStatsLabel = isMobile
        ? t('hadith')
        : hasSelectedFilters
        ? t('hadiths_count_with_filters')
        : t('hadiths_count');

    const [wordConfigOpen, setWordConfigOpen] = useState(false);

    const [narratorsColors, setNarratorsColors] = useState<NarratorColor[]>([]);

    const [fullSearchState, setFullSearchState] = useState<
        NextState | undefined
    >(undefined);

    const dependencies: SearchFilters[] = [
        SearchFilters.SEARCH,
        SearchFilters.BOOKS_FILTERS,
        SearchFilters.NARRATORS_FILTERS,
        SearchFilters.CHAPTERS_FILTERS,
        SearchFilters.SUB_CHAPTERS_FILTERS,
        SearchFilters.HADITH_TYPES_FILTERS,
        SearchFilters.HADITH_NUMBER_SEARCH,
    ];

    const [showSnackbar, setShowSnackbar] = useState<MySnackbarProps>({
        message: '',
        show: false,
    });

    const copyURLToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            if (setShowSnackbar)
                setShowSnackbar({
                    message: t('url_copied'),
                    show: true,
                });
        } catch (err) {}
    };

    const advancedActionsSx: SxProps<Theme> = isMobile
        ? {
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'row',
          }
        : {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              width: '100%',
          };

    const sortComponent = (
        <SortComponent
            disabled={!isPhraseMatch}
            sortOptions={sortOptions}
            sortBy={sortBy}
            prefix=""
            setSortBy={setSortBy}
        />
    );

    const shareActions = (
        <ShareActions
            exportDisabledTooMany={exportDisabledTooMany}
            exportDisabledZero={exportDisabledZero}
            fullSearchState={fullSearchState}
            onWordExport={() => setWordConfigOpen(true)}
            onCopy={copyURLToClipboard}
        />
    );

    const advancedActions = <Box sx={advancedActionsSx}>{shareActions}</Box>;

    const resultsStats = (
        <ResultsStats
            loading={loadingCounts}
            totalMatns={totalMatns}
            totalResults={totalResults}
            resultStatsLabel={resultStatsLabel}
        />
    );

    useEffect(() => {
        const hadithId = router.query.hadithId;
        if (!hadithId) {
            return;
        }

        const hadithFromResults = results?.find(
            (res) => res.hadith_id === hadithId,
        );
        if (hadithFromResults) {
            addAmbiguousSpan(hadithFromResults);
            setHadith(hadithFromResults);
            return;
        }

        fetch(`/api/search-hadith?id=${hadithId}`)
            .then((res) => res.json())
            .then((json) => {
                const {
                    data: [parsed],
                } = z
                    .object({ data: SearchPageHadith.array().length(1) })
                    .parse(json);

                addAmbiguousSpan(parsed);
                setHadith(parsed);
            });
    }, [router.query.hadithId, results]);

    return (
        <>
            <Paper
                elevation={0}
                variant={isMobile ? 'elevation' : 'outlined'}
                sx={{
                    px: 2,
                    py: { sm: 2 },
                    borderRadius: '10px',
                    mt: { xs: hasSelectedFilters ? 2 : 0, sm: 0 },
                }}
            >
                <DynamicSearchLayout
                    hasSelectedFilters={anyFilterApplied}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    showCollapsedBtn={false}
                    showFiltersMobileMainSearch={showFiltersMobileMainSearch}
                    setShowFiltersMobileMainSearch={
                        setShowFiltersMobileMainSearch
                    }
                    filters={(setDrawerFiltersOpen) => (
                        <MainSearchFilters
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                            narratorsColors={narratorsColors}
                            setNarratorsColors={setNarratorsColors}
                            sortBy={sortBy}
                            setLoadingCounts={setLoadingCounts}
                            showAggregateCounts
                            setDrawerFiltersOpen={setDrawerFiltersOpen}
                        />
                    )}
                    resultStatsMessage={null}
                    results={(handleFilter) => (
                        <>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="start"
                            >
                                <Grid item xs={12} md={10}>
                                    <Box
                                        sx={
                                            isMobile && !hasSelectedFilters
                                                ? { display: 'none' }
                                                : isMobile && hasSelectedFilters
                                                ? {
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      position: 'fixed',
                                                      left: 0,
                                                      right: 0,
                                                      top: 0,
                                                      zIndex: 2,
                                                  }
                                                : {
                                                      display: 'block',
                                                  }
                                        }
                                    >
                                        <SearchBarAutosuggest
                                            hasSelectedFilters={
                                                anyFilterApplied
                                            }
                                            collapsed={collapsed}
                                            setCollapsed={setCollapsed}
                                            openMobileSearchModalFromHeader={
                                                openMobileSearchModalFromHeader
                                            }
                                            setOpenMobileSearchModalFromHeader={
                                                setOpenMobileSearchModalFromHeader
                                            }
                                            onFilterClick={
                                                setShowFiltersMobileMainSearch
                                            }
                                            label={t('search')}
                                            componentId={SearchFilters.SEARCH}
                                            searchPlaceholder={t(
                                                'search_in_hadiths',
                                            )}
                                            historyLocalStorageKey="hadithSearchHistory"
                                            searchType={searchType}
                                            sortBy={sortBy}
                                            initialTextSearch={textSearch}
                                            setTextSearch={setTextSearch}
                                            setSearchType={setSearchType}
                                            setSortBy={setSortBy}
                                            setLoadingCounts={setLoadingCounts}
                                        />
                                        {isMobile && hasSelectedFilters && (
                                            <FiltersSelected
                                                addShadow={true}
                                                setLoadingCounts={
                                                    setLoadingCounts
                                                }
                                                setSearchType={setSearchType}
                                                setSortBy={setSortBy}
                                                setTextSearch={setTextSearch}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={0}
                                    md={2}
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            md: 'flex',
                                        },
                                        flexDirection: 'row',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <NumberSearch
                                        label={t('search_by_hadith_number')}
                                        componentId={
                                            SearchFilters.HADITH_NUMBER_SEARCH
                                        }
                                        dataField={
                                            env === 'prod'
                                                ? NarrationsDataFields.NUMBER
                                                : NarrationsDataFields.HADITH_SERIAL_ID
                                        }
                                        initialHadithSerialId={
                                            initialHadithNumberSearch as string
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {!isMobile && (
                                <FiltersSelected
                                    setLoadingCounts={setLoadingCounts}
                                    setSearchType={setSearchType}
                                    setSortBy={setSortBy}
                                    setTextSearch={setTextSearch}
                                />
                            )}
                            <ResultItemList
                                hasSelectedFilters={hasSelectedFilters}
                                componentId={SearchFilters.RESULT}
                                dataField={
                                    isTokenMatch
                                        ? NarrationsDataFields.HADITH_SERIAL_ID
                                        : '_id'
                                }
                                onData={(items) => {
                                    const { data } = z
                                        .object({
                                            data: SearchPageHadith.array(),
                                        })
                                        .parse(items);
                                    setResults(data);
                                    setTotalResults(
                                        items.resultStats.numberOfResults,
                                    );
                                }}
                                size={size}
                                resultItems={(items) => (
                                    <ResultItems
                                        items={items as HadithResultItemsProps}
                                        size={size}
                                        narratorsColors={narratorsColors}
                                        setShowSnackbar={setShowSnackbar}
                                        resultsStats={resultsStats}
                                        totalResults={totalResults}
                                        firstRender={firstRender}
                                    />
                                )}
                                // this is for showing the skeleton when fetching more results
                                loader={Array(size * 2)
                                    .fill(0)
                                    .map(() => (
                                        <HadithSkeleton
                                            key={uuidv4()}
                                            withActions
                                        />
                                    ))}
                                stream={true}
                                showPagination={false}
                                dependencies={{
                                    and: dependencies,
                                }}
                                defaultQuery={() =>
                                    getMainSearchDefaultQuery(
                                        sortBy,
                                        searchType,
                                    )
                                }
                                includeFields={getIncludeFields()}
                                resultStatsMessage={
                                    !isMobile ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1,
                                            }}
                                        >
                                            {resultsStats}
                                        </Box>
                                    ) : null
                                }
                                headerFirstLevel={
                                    <TokenPhraseTabs
                                        textSearch={!!textSearch}
                                        isTokenMatch={isTokenMatch}
                                        onTokenClick={() => {
                                            fetch('/api/search_analytics', {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    search: router.query.search,
                                                    type: 'token',
                                                }),
                                                headers: {
                                                    'Content-Type':
                                                        'application/json',
                                                },
                                            });
                                            setLoadingCounts(true);
                                            setSearchType('token');
                                            setSortBy(SORT_OPTIONS.SIMILARITY);
                                            // reflect exact_match in URL query params
                                            router.query.search_type = 'token';
                                            router.query.sort =
                                                SORT_OPTIONS.SIMILARITY;
                                            router.push(router, undefined, {
                                                shallow: true,
                                            });
                                        }}
                                        onPhraseClick={() => {
                                            fetch('/api/search_analytics', {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    search: router.query.search,
                                                    type: 'phrase',
                                                }),
                                                headers: {
                                                    'Content-Type':
                                                        'application/json',
                                                },
                                            });
                                            setLoadingCounts(true);
                                            setSearchType('phrase');
                                            setSortBy(
                                                SORT_OPTIONS.AUTHENTICITY,
                                            );
                                            // get rid of all state except the three following
                                            router.query = {
                                                search_type: 'phrase',
                                                sort: SORT_OPTIONS.AUTHENTICITY,
                                                search: router.query.search,
                                            };
                                            router.push(router, undefined, {
                                                shallow: true,
                                            });
                                        }}
                                    />
                                }
                                headerSecondLevel={
                                    isMobile ? (
                                        <SecondLevelHeaderMobile
                                            resultsStats={resultsStats}
                                            shareActions={shareActions}
                                            sortComponent={
                                                textSearch
                                                    ? sortComponent
                                                    : null
                                            }
                                        />
                                    ) : (
                                        <SecondHeaderLevelDesktop
                                            advancedActions={advancedActions}
                                            sortComponent={
                                                textSearch
                                                    ? sortComponent
                                                    : null
                                            }
                                        />
                                    )
                                }
                                hideReport
                                hideFilter
                                onFilterClick={handleFilter}
                            />
                            <StateProvider
                                includeKeys={[
                                    'value',
                                    'hits',
                                    'aggregations',
                                    'error',
                                    'query',
                                    'aggs',
                                    'size',
                                    'includeFields',
                                    'react',
                                    'sort',
                                    'highlight',
                                ]}
                                onChange={(_, nextState: NextState) => {
                                    const isEmptySearch = MatchAll.safeParse(
                                        nextState.result.query,
                                    ).success;

                                    const newAnyFiltersApplied =
                                        anyMainSearchFilterApplied(nextState);

                                    setHasSelectedFilters(!isEmptySearch);
                                    setSelectedFiltersPoint(!isEmptySearch);
                                    setAnyFilterApplied(newAnyFiltersApplied);

                                    // if (
                                    //     nextState[SearchFilters.RESULT]
                                    //         ?.aggregations
                                    // ) {
                                    //     setTotalMatns(
                                    //         nextState[SearchFilters.RESULT]
                                    //             ?.aggregations.unique_matns
                                    //             ?.value,
                                    //     );
                                    // }

                                    if (!isEmptySearch) {
                                        setFullSearchState(nextState);
                                    } else {
                                        // if the full search state is undefined, the excel and word export buttons
                                        // will go away. We don't want them to appear in the default view when no filters
                                        // are selected
                                        setFullSearchState(undefined);
                                    }

                                    if (!countsTimeoutId.current) {
                                        countsTimeoutId.current = setTimeout(
                                            () => {
                                                setLoadingCounts(false);
                                                countsTimeoutId.current =
                                                    undefined;
                                                firstRender.current = false;
                                            },
                                            2000,
                                        );
                                    }
                                }}
                            />
                        </>
                    )}
                />
                {wordConfigOpen && (
                    <CopyHadithModal
                        onClose={() => setWordConfigOpen(false)}
                        setShowSnackbar={setShowSnackbar}
                        hadithHtml={sampleHadith.hadith}
                        hadith={sampleHadith}
                        title={t('word_config')}
                        downloadText={t('download_word')}
                        onWordExport={async (config: WordConfig) => {
                            const { onWordExport } = await import(
                                '@utils/export'
                            );

                            if (fullSearchState) {
                                onWordExport(fullSearchState, '', config);
                            }
                        }}
                    />
                )}
                {!!hadith && router.query.hadithId && (
                    <MainModal
                        originalHadith={hadith}
                        handleClose={() => {
                            router.push(returnHref, undefined, {
                                shallow: true,
                            });
                            setHadith(undefined);
                        }}
                        moveTitleToRight={true}
                        title={
                            isMobile
                                ? getBookAndIdText(
                                      hadith.book_name,
                                      hadith.number[0],
                                  )
                                : ''
                        }
                    >
                        <Paper
                            elevation={0}
                            variant={isMobile ? 'elevation' : 'outlined'}
                            sx={{
                                border: 'none',
                                height: 'fit-content',
                            }}
                        >
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                flexDirection={'column'}
                            >
                                {!!hadith ? (
                                    <Box width="100%">
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block',
                                                },
                                            }}
                                        >
                                            <HadithResultItem
                                                key={hadith?.hadith_id}
                                                hadith_object={hadith}
                                                rightActions={[]}
                                                setShowSnackbar={
                                                    setShowSnackbar
                                                }
                                            />
                                        </Box>
                                        <HadithController
                                            hadith={hadith}
                                            setShowSnackbar={setShowSnackbar}
                                        />
                                    </Box>
                                ) : (
                                    <CircularProgress color="secondary" />
                                )}
                            </Box>
                        </Paper>
                    </MainModal>
                )}

                <MySnackbar
                    anchorOrigin={{
                        vertical: isMobile ? 'top' : 'bottom',
                        horizontal: isMobile ? 'center' : 'right',
                    }}
                    message={showSnackbar.message}
                    key="top-center"
                    open={showSnackbar.show}
                    onClose={() =>
                        setShowSnackbar({ message: '', show: false })
                    }
                    autoHideDuration={3000}
                    severity={showSnackbar.severity}
                />
                {!!hadith ? null : (
                    <ScrollToTop
                        smooth
                        top={scrollToTopMin}
                        component={
                            <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                        }
                        style={{
                            backgroundColor: colors.secondary,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bottom: '80px',
                        }}
                    />
                )}
            </Paper>
        </>
    );
};

export default MainSearch;
