import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import type { MySnackbarProps } from '@components/snackbar/Snackbar';

import { narratorsIndex } from '@config/reactive-search';
import { NarratorsDataFields } from '@constants/datafields';
import { mainSearchStateKeys } from '@constants/filters';
import { useContextualRouting } from '@hooks';
import { useParams } from '@hooks';
import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import {
    Box,
    Button,
    Divider,
    FormControl,
    Skeleton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { numberWithCommas } from '@utils/numbersDisplay';

import FullNarratorsList from './FullNarratorsList';
import HadithNarratorsList from './HadithNarratorsList';
import {
    NarratorBioView,
    NarratorCommentaryView,
    NarratorScholarsView,
    NarratorStudentsView,
} from 'components';
import { HadithViews, NarratorViews } from 'constants/views';
import { analytics } from 'lib/analytics';
import { Narrator, type NarratorsPageNarrator } from 'shared/interfaces/hadith';
import { colors } from 'theme';
import { z } from 'zod';

const HasCommentary = z.object({
    data: z.object({
        hits: z.object({
            hits: z
                .array(
                    z.object({
                        _source: z.object({
                            hasCommentary: z.boolean(),
                        }),
                    }),
                )
                .length(1),
        }),
    }),
});

type INarratorsControllerProps =
    | {
          /**
           * @description if true, will show autocomplete instead of select
           */
          autoComplete: true;
          /**
           * @description if true, will show small tabs, otherwise will show fullsize wide buttons
           */
          tabs: boolean;
          /**
           * @description list of narrators
           */
          narrators: NarratorsPageNarrator[];
          /**
           * @description defines params keys to be used in the router
           * @default ROUTING.HADITH_PAGE_NARRATOR
           */
          routing?: ROUTING;

          /**
           * @description Scrollable container within which this component resides, for infinite scrolling functionality
           */
          containerId?: string;
          setShowSnackbar: (x: MySnackbarProps) => void;

          numNarrators: number;
          loading: boolean;
      }
    | {
          autoComplete: false;
          tabs?: true;
          narrators: Narrator[];
          routing?: ROUTING;
          containerId?: string;
          setShowSnackbar: (x: MySnackbarProps) => void;
          //   these are 'undefined' because they're not actually passed in when NarratorsController is used
          //   except in the narrators/index.tsx page
          //   so not great, need to refactor
          numNarrators?: undefined;
          loading?: undefined;
      };

/**
 * @description controls which narrator tab is shown
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]/[narratorId]/[narratorView]'
 */
const NarratorsController = ({
    narrators,
    numNarrators,
    autoComplete,
    routing = ROUTING.HADITH_PAGE_NARRATOR,
    tabs,
    containerId,
    setShowSnackbar,
    loading = false,
}: INarratorsControllerProps) => {
    const { t } = useTranslation('library');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const router = useRouter();

    const [hasCommentary, setHasCommentary] = useState<boolean>(true);

    const { makeContextualHref } = useContextualRouting({
        queryToKeep: mainSearchStateKeys,
    });

    const narratorViewKey =
        routing === ROUTING.NARRATOR_PAGE
            ? 'narratorView'
            : 'hadithNarratorView';
    const narratorIdKey =
        routing === ROUTING.NARRATOR_PAGE ? 'narratorId' : 'hadithNarratorId';

    const {
        hadithId,
        [narratorIdKey]: narratorId,
        [narratorViewKey]: narratorView = NarratorViews.BIO,
    } = useParams(routing);
    const [disableNarratorViews, setDisableNarratorViews] = useState(
        narratorId == `1131`,
    );

    useEffect(() => {
        if (!narratorId) return;
        fetch(`/api/narrator-has-commentary?narratorId=${narratorId}`)
            .then((res) => res.json())
            .then((data) => {
                const {
                    data: {
                        hits: {
                            hits: [{ _source }],
                        },
                    },
                } = HasCommentary.parse(data);
                setHasCommentary(_source.hasCommentary);
            })
            .catch((err) =>
                console.error('error checking narrator commentary', err),
            );
    }, [narratorId]);

    const handleNarratorViewChange = (view: NarratorViews) => {
        if (routing === ROUTING.NARRATOR_PAGE && narratorId) {
            analytics('narrator-tabs', {
                narrator_id: narratorId,
                view,
                source: 'hadith-narrator-controller',
            });
            // don't do contextual routing, since for narrator, the Controller is the
            // main page, and not part of a modal. Contextual routing is used only to create
            // and maintain a return href when opening a modal, but we don't have a modal here
            // so don't create a context, since it then interfers with opening the hadith modal
            // from rightActions.
            router.query = {
                [narratorIdKey]: narratorId!,
                [narratorViewKey]: view,
            };
            router.push(router, undefined, { shallow: true });
        } else if (hadithId && narratorId) {
            analytics('narrator-tabs', {
                hadith_id: hadithId,
                narrator_id: narratorId,
                view,
                source: 'narrators-narrator-controller',
            });
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: HadithViews.NARRATOR,
                    [narratorIdKey]: narratorId,
                    [narratorViewKey]: view,
                }),
                undefined,
                { shallow: true },
            );
        }
    };

    const handleNarratorChange = (
        id: string | number,
        view?: NarratorViews,
    ) => {
        // Unknown Name narrator ID
        if (id == '1131') {
            setDisableNarratorViews(true);
        } else {
            setDisableNarratorViews(false);
        }

        // have to do the type cast "as" to avoid the following build error:
        // Each member of the union type has signatures, but none of those signatures are compatible with each other
        // so I cast to "id" and "is_unknown" because these are the only two fields I need here
        const newNarratorUnknown = (
            narrators as { id: string; is_unknown: boolean }[]
        ).find((narrator) => narrator.id === id)?.is_unknown;

        if (routing === ROUTING.NARRATOR_PAGE) {
            // don't do contextual routing, since for narrator, the Controller is the
            // main page, and not part of a modal. Contextual routing is used only to create
            // and maintain a return href when opening a modal, but we don't have a modal here
            // so don't create a context, since it then interfers with opening the hadith modal
            // from rightActions.

            router.query = {
                [narratorIdKey]: `${id}`,
                [narratorViewKey]:
                    id === '1131' || newNarratorUnknown
                        ? NarratorViews.BIO
                        : view ?? narratorView,
            };
            return router.push(router, undefined, { shallow: true });
        } else if (hadithId) {
            return router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: HadithViews.NARRATOR,
                    [narratorIdKey]: `${id}`,
                    [narratorViewKey]:
                        id === '1131' || newNarratorUnknown
                            ? NarratorViews.BIO
                            : view ?? narratorView,
                }),
                undefined,
                { shallow: true },
            );
        }
    };

    useEffect(() => {
        if (!narratorId && narrators.length > 0) {
            if (routing === ROUTING.NARRATOR_PAGE) {
                // don't do contextual routing, since for narrator, the Controller is the
                // main page, and not part of a modal. Contextual routing is used only to create
                // and maintain a return href when opening a modal, but we don't have a modal here
                // so don't create a context, since it then interfers with opening the hadith modal
                // from rightActions.
                router.query = {
                    [narratorIdKey]: narrators[0].id,
                    [narratorViewKey]: NarratorViews.BIO,
                };
                router.push(router, undefined, { shallow: true });
            } else {
                router.replace(
                    makeContextualHref({
                        [narratorIdKey]: narrators[0].id,
                        [narratorViewKey]: NarratorViews.BIO,
                    }),
                    undefined,
                    { shallow: true },
                );
            }
        }
    }, [narratorId, narrators]);

    //Remove duplicates from narrators list
    const uniqueIds = new Set();

    // Create a filtered array that contains only unique narrators based on their 'id'\
    // hack: https://stackoverflow.com/questions/49510832/how-to-map-over-union-array-type
    // there ought to be a better way but this should suffice for now
    // basically if you have (A | B)[] then it has a filter method, but A[] | B[] doesn't
    // so need to type assert it to (A | B)[], then type assert it back to A[] | B[], the latter is the actual type
    const uniqueNarrators = (
        narrators as (NarratorsPageNarrator | Narrator)[]
    ).filter(({ id }) => {
        if (!uniqueIds.has(id)) {
            uniqueIds.add(id);
            return true;
        }
        return false;
    }) as NarratorsPageNarrator[] | Narrator[];

    const narratorCommentaryDesktopButton = (
        <Button
            key={NarratorViews.COMMENTARY}
            size={isMobile ? 'small' : 'large'}
            variant="text"
            onClick={() => {
                handleNarratorViewChange(NarratorViews.COMMENTARY);
            }}
            sx={{
                color:
                    narratorView === NarratorViews.COMMENTARY
                        ? colors.primary
                        : '#000',
                backgroundColor:
                    narratorView === NarratorViews.COMMENTARY
                        ? colors.secondary
                        : '#fff',
                border: `1px solid ${theme.palette.divider}`,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                pt: { xs: 1, md: 1.1 },
                borderBottom: 'none',
                '&:hover': {
                    backgroundColor:
                        narratorView === NarratorViews.COMMENTARY
                            ? colors.secondary
                            : colors.hover,
                },
            }}
            disabled={disableNarratorViews || !hasCommentary}
        >
            {t('narrator_commentary')}
        </Button>
    );

    const narratorCommentaryDesktopTab = (
        <Button
            key={NarratorViews.COMMENTARY}
            variant="text"
            onClick={() => {
                handleNarratorViewChange(NarratorViews.COMMENTARY);
            }}
            sx={{
                color:
                    narratorView === NarratorViews.COMMENTARY
                        ? colors.secondary
                        : '#000',
                backgroundColor:
                    narratorView === NarratorViews.COMMENTARY
                        ? colors.hover
                        : 'transparent',
                border: `1px solid ${theme.palette.divider}`,
                borderLeft: 'none',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }}
            fullWidth
            disabled={disableNarratorViews || !hasCommentary}
        >
            {t('narrator_commentary')}
        </Button>
    );

    const narratorCommentaryMobileButton = (
        <Button
            key={NarratorViews.COMMENTARY}
            variant="text"
            onClick={() => {
                handleNarratorViewChange(NarratorViews.COMMENTARY);
            }}
            sx={{
                color:
                    narratorView === NarratorViews.COMMENTARY
                        ? colors.secondary
                        : '#000',
                backgroundColor:
                    narratorView === NarratorViews.COMMENTARY
                        ? colors.hover
                        : 'transparent',
                border: `1px solid ${theme.palette.divider}`,
                borderLeft: 'none',
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 0,
            }}
            fullWidth
            disabled={disableNarratorViews || !hasCommentary}
        >
            {t('narrator_commentary')}
        </Button>
    );

    return (
        <>
            <Box mt={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column-reverse', md: 'row' },
                        alignItems: 'center',
                        gap: 1,
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box>
                        {autoComplete ? (
                            loading || !narratorId ? (
                                <Skeleton
                                    variant="text"
                                    width="300px"
                                    height="100px"
                                />
                            ) : (
                                <Box
                                    sx={{ width: { xs: '300px', md: '500px' } }}
                                >
                                    <FullNarratorsList
                                        narrators={narrators}
                                        narratorIdFromUrl={narratorId}
                                        handleNarratorChange={
                                            handleNarratorChange
                                        }
                                    />
                                </Box>
                            )
                        ) : (
                            <FormControl
                                variant="standard"
                                sx={{ mt: { xs: 1, md: 0 }, minWidth: 300 }}
                            >
                                <HadithNarratorsList
                                    narrators={uniqueNarrators}
                                    selectedNarratorId={narratorId}
                                    handleNarratorChange={handleNarratorChange}
                                />
                            </FormControl>
                        )}
                    </Box>
                    <Box>
                        {loading || !narratorId ? (
                            <Skeleton
                                variant="text"
                                width="100px"
                                height="40px"
                            />
                        ) : (
                            <Typography
                                variant="body1"
                                sx={{ color: colors.primary }}
                            >
                                {t('narrator_list_count')} :
                                {numberWithCommas(
                                    numNarrators
                                        ? `${numNarrators}`
                                        : uniqueNarrators.length.toString(),
                                )}
                            </Typography>
                        )}
                    </Box>
                </Box>
                {tabs ? (
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: 0.5,
                            }}
                        >
                            {disableNarratorViews || !hasCommentary ? (
                                <Tooltip
                                    placement="top"
                                    title={
                                        <Typography fontSize={12}>
                                            {t('no_narrator_commentary_found')}
                                        </Typography>
                                    }
                                    enterTouchDelay={1}
                                >
                                    <span>
                                        {narratorCommentaryDesktopButton}
                                    </span>
                                </Tooltip>
                            ) : (
                                narratorCommentaryDesktopButton
                            )}
                            <Button
                                key={NarratorViews.STUDENTS}
                                size={isMobile ? 'small' : 'large'}
                                variant="text"
                                onClick={() => {
                                    handleNarratorViewChange(
                                        NarratorViews.STUDENTS,
                                    );
                                }}
                                sx={{
                                    color:
                                        narratorView === NarratorViews.STUDENTS
                                            ? colors.primary
                                            : '#000',
                                    backgroundColor:
                                        narratorView === NarratorViews.STUDENTS
                                            ? colors.secondary
                                            : '#fff',
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottom: 'none',
                                    pt: { xs: 1, md: 1.1 },
                                    '&:hover': {
                                        backgroundColor:
                                            narratorView ===
                                            NarratorViews.STUDENTS
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                disabled={disableNarratorViews}
                            >
                                {t(NarratorViews.STUDENTS)}
                            </Button>
                            <Button
                                key={NarratorViews.SCHOLARS}
                                size={isMobile ? 'small' : 'large'}
                                variant="text"
                                onClick={() => {
                                    handleNarratorViewChange(
                                        NarratorViews.SCHOLARS,
                                    );
                                }}
                                sx={{
                                    color:
                                        narratorView === NarratorViews.SCHOLARS
                                            ? colors.primary
                                            : '#000',
                                    backgroundColor:
                                        narratorView === NarratorViews.SCHOLARS
                                            ? colors.secondary
                                            : '#fff',
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottom: 'none',
                                    pt: { xs: 1, md: 1.1 },
                                    '&:hover': {
                                        backgroundColor:
                                            narratorView ===
                                            NarratorViews.SCHOLARS
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                disabled={disableNarratorViews}
                            >
                                {t(NarratorViews.SCHOLARS)}
                            </Button>
                            <Button
                                key={NarratorViews.BIO}
                                variant="text"
                                size={isMobile ? 'small' : 'large'}
                                onClick={() => {
                                    handleNarratorViewChange(NarratorViews.BIO);
                                }}
                                sx={{
                                    color:
                                        narratorView === NarratorViews.BIO
                                            ? colors.primary
                                            : '#000',
                                    backgroundColor:
                                        narratorView === NarratorViews.BIO
                                            ? colors.secondary
                                            : '#fff',
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottom: 'none',
                                    pt: { xs: 1, md: 1.1 },
                                    '&:hover': {
                                        backgroundColor:
                                            narratorView === NarratorViews.BIO
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                            >
                                {t(NarratorViews.BIO)}
                            </Button>
                        </Box>
                        <Divider
                            orientation="horizontal"
                            sx={{
                                mt: 0.5,
                                backgroundColor: colors.secondary,
                                borderBottomWidth: 2,
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: { xs: '90%', sm: '100%' },
                            mt: 2,
                            mx: 'auto',
                        }}
                    >
                        {!isMobile ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    key={NarratorViews.BIO}
                                    variant="text"
                                    onClick={() => {
                                        handleNarratorViewChange(
                                            NarratorViews.BIO,
                                        );
                                    }}
                                    sx={{
                                        color:
                                            narratorView === NarratorViews.BIO
                                                ? colors.secondary
                                                : '#000',
                                        backgroundColor:
                                            narratorView === NarratorViews.BIO
                                                ? colors.hover
                                                : 'transparent',
                                        border: `1px solid ${theme.palette.divider}`,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                    }}
                                    fullWidth
                                >
                                    {t(NarratorViews.BIO)}
                                </Button>

                                <Button
                                    key={NarratorViews.SCHOLARS}
                                    variant="text"
                                    onClick={() => {
                                        handleNarratorViewChange(
                                            NarratorViews.SCHOLARS,
                                        );
                                    }}
                                    sx={{
                                        color:
                                            narratorView ===
                                            NarratorViews.SCHOLARS
                                                ? colors.secondary
                                                : '#000',
                                        backgroundColor:
                                            narratorView ===
                                            NarratorViews.SCHOLARS
                                                ? colors.hover
                                                : 'transparent',
                                        border: `1px solid ${theme.palette.divider}`,
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                    fullWidth
                                    disabled={disableNarratorViews}
                                >
                                    {t(NarratorViews.SCHOLARS)}
                                </Button>
                                <Button
                                    key={NarratorViews.STUDENTS}
                                    variant="text"
                                    onClick={() => {
                                        handleNarratorViewChange(
                                            NarratorViews.STUDENTS,
                                        );
                                    }}
                                    sx={{
                                        color:
                                            narratorView ===
                                            NarratorViews.STUDENTS
                                                ? colors.secondary
                                                : '#000',
                                        backgroundColor:
                                            narratorView ===
                                            NarratorViews.STUDENTS
                                                ? colors.hover
                                                : 'transparent',
                                        border: `1px solid ${theme.palette.divider}`,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                    fullWidth
                                    disabled={disableNarratorViews}
                                >
                                    {t(NarratorViews.STUDENTS)}
                                </Button>
                                {disableNarratorViews || !hasCommentary ? (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <Typography fontSize={12}>
                                                {t(
                                                    'no_narrator_commentary_found',
                                                )}
                                            </Typography>
                                        }
                                        enterTouchDelay={1}
                                    >
                                        <span style={{ width: '100%' }}>
                                            {narratorCommentaryDesktopTab}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    narratorCommentaryDesktopTab
                                )}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <Button
                                        key={NarratorViews.BIO}
                                        variant="text"
                                        onClick={() => {
                                            handleNarratorViewChange(
                                                NarratorViews.BIO,
                                            );
                                        }}
                                        sx={{
                                            color:
                                                narratorView ===
                                                NarratorViews.BIO
                                                    ? colors.secondary
                                                    : '#000',
                                            backgroundColor:
                                                narratorView ===
                                                NarratorViews.BIO
                                                    ? colors.hover
                                                    : 'transparent',
                                            border: `1px solid ${theme.palette.divider}`,
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderBottom: 0,
                                        }}
                                        fullWidth
                                    >
                                        {t(NarratorViews.BIO)}
                                    </Button>
                                    <Button
                                        key={NarratorViews.STUDENTS}
                                        variant="text"
                                        onClick={() => {
                                            handleNarratorViewChange(
                                                NarratorViews.STUDENTS,
                                            );
                                        }}
                                        sx={{
                                            color:
                                                narratorView ===
                                                NarratorViews.STUDENTS
                                                    ? colors.secondary
                                                    : '#000',
                                            backgroundColor:
                                                narratorView ===
                                                NarratorViews.STUDENTS
                                                    ? colors.hover
                                                    : 'transparent',
                                            border: `1px solid ${theme.palette.divider}`,
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderTopLeftRadius: 0,
                                            // borderBottomLeftRadius: 0,
                                        }}
                                        fullWidth
                                        disabled={disableNarratorViews}
                                    >
                                        {t(NarratorViews.STUDENTS)}
                                    </Button>
                                </Box>

                                <Box>
                                    {disableNarratorViews || !hasCommentary ? (
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <Typography fontSize={12}>
                                                    {t(
                                                        'no_narrator_commentary_found',
                                                    )}
                                                </Typography>
                                            }
                                            enterTouchDelay={1}
                                        >
                                            <span>
                                                {narratorCommentaryMobileButton}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        narratorCommentaryMobileButton
                                    )}

                                    <Button
                                        key={NarratorViews.SCHOLARS}
                                        variant="text"
                                        onClick={() => {
                                            handleNarratorViewChange(
                                                NarratorViews.SCHOLARS,
                                            );
                                        }}
                                        sx={{
                                            color:
                                                narratorView ===
                                                NarratorViews.SCHOLARS
                                                    ? colors.secondary
                                                    : '#000',
                                            backgroundColor:
                                                narratorView ===
                                                NarratorViews.SCHOLARS
                                                    ? colors.hover
                                                    : 'transparent',
                                            border: `1px solid ${theme.palette.divider}`,
                                            borderLeft: 'none',
                                            borderTopRightRadius: 0,
                                            // borderBottomRightRadius: 0,
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }}
                                        fullWidth
                                        disabled={disableNarratorViews}
                                    >
                                        {t(NarratorViews.SCHOLARS)}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}

                <Box>
                    {narratorView === NarratorViews.BIO && (
                        <NarratorBioView narratorId={narratorId} />
                    )}
                    {narratorView === NarratorViews.SCHOLARS && (
                        <Box sx={{ mt: 2 }}>
                            <Alert severity="warning">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'in_progress_narrations_feature',
                                        ),
                                    }}
                                ></span>
                            </Alert>
                            <NarratorScholarsView
                                narratorId={narratorId}
                                containerId={containerId}
                                setShowSnackbar={setShowSnackbar}
                                routing={routing}
                            />
                        </Box>
                    )}
                    {narratorView === NarratorViews.STUDENTS && (
                        <Box sx={{ mt: 2 }}>
                            <Alert severity="warning">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'in_progress_narrations_feature',
                                        ),
                                    }}
                                ></span>
                            </Alert>
                            <NarratorStudentsView
                                narratorId={narratorId}
                                containerId={containerId}
                                setShowSnackbar={setShowSnackbar}
                                routing={routing}
                            />
                        </Box>
                    )}
                    {narratorView === NarratorViews.COMMENTARY && (
                        <Box sx={{ mt: 2 }}>
                            <NarratorCommentaryView
                                narratorId={narratorId}
                                containerId={containerId}
                                routing={routing}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default NarratorsController;
