import { useState } from 'react';

import { useRouter } from 'next/router';

import { type MySnackbarProps } from '@components/snackbar/Snackbar';
import RoadsGraph from '@components/views/RoadsViews/RoadsGraph/RoadsGraph';

import useTranslation from '@hooks/useTranslation';
import { Box, Button, useTheme } from '@mui/material';

import { RoadsHadithsView } from 'components';
import { RoadsViews } from 'constants/views';
import { analytics } from 'lib/analytics';
import {
    type NarrationsType,
    type SearchPageHadith,
} from 'shared/interfaces/hadith';
import { colors } from 'theme';

interface IRoadsControllerProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
}

/**
 * @description controlls which roads tab is shown
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]/[roadsView]'
 */
const RoadsController = ({
    hadith,
    setShowSnackbar,
}: IRoadsControllerProps) => {
    const { t } = useTranslation('library');

    const [narrationsType, setNarrationsType] =
        useState<NarrationsType>('roads');

    const router = useRouter();
    const theme = useTheme();
    const [roadsView, setRoadsView] = useState(router.query['roadsView']);
    const [collapsed, setCollapsed] = useState(true);

    const handleRoadsViewChange = (view: RoadsViews) => {
        analytics('roads-tabs', {
            hadith_id: hadith.hadith_id,
            view,
            source: 'roads-controller',
        });
        router.push(
            { query: { ...router.query, roadsView: view } },
            undefined,
            { shallow: true },
        );
        setRoadsView(view);
    };

    return (
        <Box mt={1}>
            <Box sx={{ width: '100%', mt: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Button
                            key={RoadsViews.ROADS}
                            onClick={() => {
                                handleRoadsViewChange(RoadsViews.ROADS);
                            }}
                            sx={{
                                color:
                                    roadsView === RoadsViews.ROADS
                                        ? colors.secondary
                                        : '#121212',
                                backgroundColor:
                                    roadsView === RoadsViews.ROADS
                                        ? colors.hover
                                        : 'transparent',
                                border: `1px solid ${theme.palette.divider}`,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderRight: 'none',
                            }}
                            fullWidth
                        >
                            {t('hadiths')}
                        </Button>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Button
                            key={RoadsViews.GRAPH}
                            onClick={() => {
                                handleRoadsViewChange(RoadsViews.GRAPH);
                            }}
                            sx={{
                                color:
                                    roadsView === RoadsViews.GRAPH
                                        ? colors.secondary
                                        : '#121212',
                                backgroundColor:
                                    roadsView === RoadsViews.GRAPH
                                        ? colors.hover
                                        : 'transparent',
                                border: `1px solid ${theme.palette.divider}`,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderRight: 'none',
                            }}
                            fullWidth
                        >
                            {t(RoadsViews.GRAPH)}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box>
                {roadsView === RoadsViews.GRAPH ? (
                    <RoadsGraph
                        hadith={hadith}
                        narrationsType={narrationsType}
                        setNarrationsType={setNarrationsType}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
                ) : (
                    <RoadsHadithsView
                        hadith={hadith}
                        narrationsType={narrationsType}
                        setNarrationsType={setNarrationsType}
                        setShowSnackbar={setShowSnackbar}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
                )}
            </Box>
        </Box>
    );
};

export default RoadsController;
