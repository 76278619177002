import FilterList from '@components/filters/FilterList';

import useTranslation from '@hooks/useTranslation';
import { Divider } from '@mui/material';

import { HadithCommentaryDataFields } from 'constants/datafields';
import { HadithCommentaryViewFilters } from 'constants/filters';

const HadithCommentaryFilters = ({
    setDrawerFiltersOpen,
}: {
    setDrawerFiltersOpen: (open: boolean) => void;
}) => {
    const { t } = useTranslation('library');

    return (
        <>
            {/* Search By Commentary Authors */}
            <FilterList
                label={t('commentary_authors')}
                componentId={HadithCommentaryViewFilters.COMMENTATOR_FILTERS}
                dataField={HadithCommentaryDataFields.COMMENTARY_AUTHOR_NAME}
                dependencies={{
                    and: [
                        HadithCommentaryViewFilters.HADITHS_IDS,
                        HadithCommentaryViewFilters.BOOKS_FILTERS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="comm_"
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Commentary Books */}
            <FilterList
                label={t('commentary_books')}
                componentId={HadithCommentaryViewFilters.BOOKS_FILTERS}
                dataField={HadithCommentaryDataFields.COMMENTARY_BOOK_NAME}
                dependencies={{
                    and: [
                        HadithCommentaryViewFilters.COMMENTATOR_FILTERS,
                        HadithCommentaryViewFilters.HADITHS_IDS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="comm_"
            />
        </>
    );
};

export default HadithCommentaryFilters;
